export const loadingLength = 5;
export const admins: string[] = [
  'gs622@cornell.edu', // Grace
  'tpp38@cornell.edu', // Sophia
  'km653@cornell.edu', // Kevin
  'egk46@cornell.edu', // Ella
  'dj263@cornell.edu', // Daniel
  'ko353@cornell.edu', // Kea-Roy
  'cl2622@cornell.edu', // Casper
  'dm769@cornell.edu', // David
  'jl3587@cornell.edu', // Janet
  'vw92@cornell.edu', // Vicky
  'pt427@cornell.edu', // Parsa
  'jl4427@cornell.edu', // Jaeyoung
  'jig47@cornell.edu', // Jacob
  'el728@cornell.edu', // Erica
  'aa2724@cornell.edu', // Arsh
];
